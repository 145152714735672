import { defineComponent, PropType } from 'vue'
import './index.scss'
export type Direction = 'row' | 'column' | 'row-reverse' | 'column-reverse'
export type Position = 'start' | 'center' | 'end'
export default defineComponent({
  name: 'GfrGrid',
  props: {
    direction: {
      type: String as PropType<Direction>,
      default: 'row'
    },
    position: {
      type: String as PropType<Position>,
      default: 'center'
    },
    gap: {
      type: Number,
      default: 0
    }
  },
  setup(props, { slots }) {
    return () => (
      <div
        class={['gfr-grid', `gfr-grid-${props.direction}`, `gfr-grid-${props.position}`]}
        style={Boolean(props.gap) && `gridGap: ${props.gap / 16}rem`}
      >
        {slots.default?.()}
      </div>
    )
  }
})
